<template>
  <main
    class="flex min-h-screen flex-col items-center justify-center gap-8 bg-black/25 bg-[url('~/assets/images/Background_Login.webp')] bg-cover bg-blend-overlay md:flex md:flex-row"
  >
    <NuxtLink to="/" class="shrink-0">
      <NuxtImg
        src="/images/White_Logo.webp"
        alt="white horizontal logo"
        class="h-20 shrink-0 sm:h-24 lg:px-0"
      />
    </NuxtLink>
    <div
      class="mx-4 flex w-full max-w-xl flex-col justify-center self-center rounded-xl bg-surface drop-shadow-xl"
    >
      <div class="rounded-xl bg-surface-container-lowest p-8">
        <slot />
      </div>
    </div>
  </main>
</template>
